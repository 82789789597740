import * as React from "react"
import styled from '@emotion/styled';

import Container from '../components/layout/container'
import SEO from '../components/seo'

// styles
import "./../styling/libraries.scss"
import { colors } from "../styling/colors";

const Table = styled.table`
  padding: 1em;
  width: 100%;
  border: 1px solid ${colors.blue02};
  th, td {
    padding: 1em;
  }
`

// markup
const CookiesPage = () => {

  return (
    <Container>

      <SEO 
        title="Informasjonskapsler"
        description=""
        pathname="/cookies-explained"
      />

      <div className="container">

        <div className="section py-0 mb-6">
          
          <div className="columns">
            <div className="column is-offset-2 is-8 mt-6">

              <h1 className="title is-size-1">Informasjonskapsler</h1>

              <p className="is-size-4">
                Vi bruker informasjonskapsler til å sikre at nettsiden fungerer på best mulig måte, analysere trafikken på nettsiden vår og vise deg relevant innhold. Her kan du lese mer om informasjonskapslene vi bruker.
              </p>

              <p>Når du besøker medhjelper.com samler vi brukerstatistikk om dine bevegelser på nettsiden og lagrer disse i en tredjepartsløsning for analyse (Google Analytics) slik at vi kan vise deg relevant innhold og gi en god brukeropplevelse. Dataene inneholder ingen personinformasjon, og det er ikke mulig for oss å spore dataene tilbake til den enkelte brukeren.</p>

              <Table>
                <thead>
                  <tr>
                    <th>Navn</th>
                    <th>Varighet</th>
                    <th>Formål</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>cookieConsent</td>
                    <td>2 år</td>
                    <td>Husker cookie-innstillingene dine for dette nettstedet.</td>
                  </tr>
                  <tr>
                    <td>_ga</td>
                    <td>2 år</td>
                    <td>Google Analytics samler anonymiserte data om bruk av nettstedet.</td>
                  </tr>
                </tbody>
              </Table>

            </div>
          </div>

        </div>

        
      </div>

    </Container>
  )
}

export default CookiesPage
